
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound';

import Designation from './Designation/Designation';


import AreaMaster from './AreaMaster/AreaMaster';
import AddArea from './AreaMaster/AddArea';
import EmployeeMaster from './EmployeeMaster/EmployeeMaster';
import AddEmployee from './EmployeeMaster/AddEmployee/AddEmployee';

import AddHeadquarter from './Headquarter/AddHeadquarter';
import Headquarter from './Headquarter/Headquarter';
import RoleRightMaster from '../Admin/User/RoleRightMaster';
import CollectionTranscationReport from '../Reports/Collection_Transaction_Report/CollectionTranscationReport';





const componentPaths: any = {
    "Headquarter": Headquarter,
    "Designation": Designation,
    "Area": AreaMaster,
    "Employee": AddEmployee, // Chnage to Add Employee Master
    "rights" : RoleRightMaster,
    "collectiontranscation": CollectionTranscationReport,
    
};

const componentActionPaths: any = {
    "Headquarter": AddHeadquarter,
    "Employee": AddEmployee,
    "AddAreaList": AddArea,
}




const MasterRouting2 = () => {
    const { componentName } = useParams<{ componentName: string }>();
    const { action } = useParams<{ action: string }>();
    const { userRight } = useParams<{ userRight: string }>();
    const { pageId } = useParams<{ pageId: string }>();
    

    const [Component, setComponent] = useState<React.ComponentType<any> | null>(null);
    // const [pageId, setPageId] = useState(0);

    const userRightsPath: any = {
        "View": Component, 
        "Edit": Component,
    }

    useEffect(() => {

        const importComponent = async () => {
            
            // if (action != null) {
            //     if(userRight == null)
            //     {
            //         if (componentActionPaths[action]) {
            //             setComponent(() => componentActionPaths[action]);
            //         }
            //         else {
            //             setComponent(() => null)
            //         }
            //     }
            //     else
            //     {
            //         if(userRightsPath[userRight]) {
            //             setComponent(() => componentActionPaths[action]);
            //         }
            //         else
            //         {
            //             setComponent(() => null)
            //         }
            //     }
                
            // }
            // else {
            //     if (componentName != null) {
            //         if (componentPaths[componentName]) {
            //             setComponent(() => componentPaths[componentName]);
            //         }
            //         else {
            //             setComponent(() => null)
            //         }

            //     }

            // }
            if (pageId != null) {
                if (componentName != null) {
                    if (componentActionPaths[componentName]) {
                        setComponent(() => componentActionPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }   
            }
            else if(action != null)
            {
                
                
                if (componentName != null) {
                    if (componentActionPaths[componentName]) {
                        setComponent(() => componentActionPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }  
            }
            else {
                if (componentName != null) {
                    if (componentPaths[componentName]) {
                        setComponent(() => componentPaths[componentName]);
                    }
                    else {
                        setComponent(() => null)
                    }

                }

            }

        };
        importComponent();
    }, [componentName, pageId]);

    if (!Component) return <PageNotFound />;

    return <Component />

};

export default MasterRouting2;




