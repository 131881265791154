import { commanServicer } from "../Commen_Services/common.services";

export const RequestReport = {
    SaveorUpdate,
  
}

async function SaveorUpdate(RequesReportData : any) {
    // console.log(MasterData);
    return await commanServicer.fetchRequest(JSON.stringify(RequesReportData), true, 'RequestReport/saveorupdaterequestreport','POST');
}