import React, { useEffect, useState } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaFilter } from "react-icons/fa";
import { TextField, FormControl, InputLabel, MenuItem, Select, Chip, Stack, Autocomplete, Box } from "@mui/material";
import Popfilterform from "../Commen_Component/Popup/Popupfilterform";
import ExportToCSV from "../Commen_Component/Export/ExportToCSV";
import { userServicer } from "../Commen_Services/user.services";
import { Add, Download } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AccessLevel, PageEnums } from "../../_enums/enum.services";
import { RequestReport } from "../Commen_Services/RequestReport.Service";

interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
}

interface RowData {
    [key: string]: any;
}

// interface reportData {
//     pageId: Number;
//     reportName: string;
//     exportReportName: string;
//     viewButtonsSum: string;
//     hasFixParameter: string;
//     defaultSearchList: any[];
//     cmdType: Number;
// }

interface ZotaHeaderSearchProps {
    pageID: string;
    columnData: ColumnData[];
    tableData: RowData[];
    sendFilterData: ({ key: string; value: any }[]);
    sendDataToParent: (event: { key: string; value: any }[]) => void;
    buttonSum: Number[];
    //sendDataToParent: ({ key: string; value: any }[]);
    ReportConfigData: RowData[];
    //ReportConfigData: reportData[];
    csvHeaderColumns: RowData[];

}

const EnhancedSearchbar: React.FC<ZotaHeaderSearchProps> = ({ pageID, columnData, buttonSum, sendFilterData, sendDataToParent, ReportConfigData, tableData, csvHeaderColumns }) => {
    const [selectedField, setSelectedField] = useState<string>("");
    const [selectedFieldforChips, setSelectedFieldforChips] = useState<string>("");
    const [filterData, setFilterData] = useState<{ key: string; value: any }[]>([]);
    const [filterDataforChips, setFilterDataforChips] = useState<{ key: string; value: any }[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [tableColumns, setTableColumns] = useState<ColumnData[]>(columnData);
    const [showDrop, setshowDrop] = useState(false);
    const [options, setOptions] = useState([]);
    const [dropDownField, setDropDownField] = useState("");
    const navigate = useNavigate();
    const [changeselectedField, setChangeSelectedField] = useState(true);
    const [datalength, setDatalength] = useState(0);

    const handleDatafromPOP = (data: { key: string; value: any }[]) => {
        
        let updatedFilterData = [...filterData];
        data.forEach(({ key, value }) => {

            const index = updatedFilterData.findIndex(item => item.key === key);
            if (index !== -1) {

                if (value === "") {
                    //updatedFilterData.pop(index);
                    updatedFilterData.splice(index, 1);

                }
                else {
                    updatedFilterData[index] = { key, value };
                }
            } else {

                updatedFilterData.push({ key, value });
            }
        });

        //setFilterData(updatedFilterData);
        data.length === 0 ? setFilterData(data) : setFilterData(updatedFilterData.filter(item => item.value !== "")); //updated to data sync with popup filter and headersearch
    };

    const handleDatafromPOPforchips = (data: { key: string; value: any }[]) => {  
        let updatedFilterDataforChips = [...filterDataforChips];

        data.forEach(({ key, value }) => {

            const index = updatedFilterDataforChips.findIndex(item => item.key === key);
            if (index !== -1) {
                if (value === "") {
                    updatedFilterDataforChips.splice(index, 1);
                }
                else {
                    updatedFilterDataforChips[index] = { key, value };
                }
            } else {

                updatedFilterDataforChips.push({ key, value });
            }
        });

        data.length === 0 ? setFilterDataforChips(data) : setFilterDataforChips(updatedFilterDataforChips.filter(item => item.value !== "")); //updated to data sync with popup filter and headersearch
    }



    useEffect(() => {

        let xy = sendFilterData;
        let x1: { key: any; value: any; }[] = [];
        xy.map((data: any) => {

            columnData.map((item: any) => {
                if (item.tableFieldName === data.key) {

                    x1.push({ key: item.headerName, value: data.value })

                }
            })
        })
        handleDatafromPOP(sendFilterData);
        handleDatafromPOPforchips(x1);

    }, [sendFilterData])



    useEffect(() => {
        setDatalength(filterData.length);
        sendDataToParent(filterData);
    }, [filterData]);


    const handleChangeMUI = (e: any) => {
        setSelectedField(e.target.value as string);
        let updatedcolumnData = columnData.filter(item => item.tableFieldName === e.target.value);
        setSelectedFieldforChips(updatedcolumnData[0].headerName as string)

        const filterdColumData = columnData.filter(item => item['tableFieldName'] === e.target.value);

        if (filterdColumData[0].autoSearchKey != null) {
            setshowDrop(true);
            setDropDownField(filterdColumData[0].autoSearchKey as string)
        }
        else {
            setshowDrop(false);
        }


    };

    const handleDelete = (index: number) => {
        const newData = [...filterData];
        const newDataforChips = [...filterDataforChips];
        newData.splice(index, 1);
        newDataforChips.splice(index, 1);
        setFilterData(newData);
        setFilterDataforChips(newDataforChips)
        sendDataToParent(newData);
        setshowDrop(false);
    };

    const handleAddButton = () => {

        let url = '/';

        let keyName = null;
        let enumkey = Object.values(PageEnums).filter((item: any) => item === Number(pageID));

        if (enumkey !== null || enumkey !== undefined) {
            for (const key in PageEnums) {
                if (Object.prototype.hasOwnProperty.call(PageEnums, key)) {
                    if (PageEnums[key as keyof typeof PageEnums] === Number(enumkey)) {
                        keyName = key;
                        break;
                    }
                }
            }

            url = String("/Master/").concat(String(keyName)).concat(String("/0"));

        }
        else {
            url = String("Add/").concat(pageID);
        }

    


        navigate(url);

    }

    const requestForReport = () => {

        console.log(filterData);
        var tempFilterData = filterData.map(item => `${item.key}=${item.value}`).join(',');
        console.log(tempFilterData);
        var tempExportColumns = csvHeaderColumns.map(item => item.tableFieldName).join(',');
        console.log(tempExportColumns);
        console.log(ReportConfigData);
        

        const data = {
            "reportPageId": pageID,
            "report_FilterColumn": tempFilterData,
            "report_Exportcolumn": tempExportColumns,
            "reportName" : ReportConfigData,
          }
        RequestReport.SaveorUpdate(data);
    }

    useEffect(() => {

        if (datalength === 1) {
            if (filterData[0] !== undefined) {
                setSelectedField(filterData[0].key as string);
                let h1 = tableColumns.filter(item => item.tableFieldName === filterData[0].key);
                if (h1.length > 0) {
                    if (h1[0].autoSearchKey !== null) {
                        setshowDrop(true);
                        setDropDownField(h1[0].autoSearchKey as string)
                    }
                    else {
                        setshowDrop(false);
                    }
                }
            }

        }
    }, [filterData, datalength]);


    useEffect(() => {
        if (datalength == 1) {
            if (filterDataforChips[0] !== undefined) {
                setSelectedFieldforChips(filterDataforChips[0].key);
            }
        }
    }, [filterDataforChips, datalength])

    useEffect(() => {
        changeselectedField ? setChangeSelectedField(false) : setChangeSelectedField(true);
        setDatalength(0)
    }, [selectedField])

    useEffect(() => {

        const filteredColumnData = columnData !== undefined && columnData.filter((item: any) => item.showFieldDDL === true);
        setTableColumns(filteredColumnData as ColumnData[]);
        setSelectedField((filteredColumnData as any)[0]['tableFieldName']); // to prevent default search /:Jignesh
        setSelectedFieldforChips((filteredColumnData as any)[0]['headerName']); // to prevent default search  /:Jignesh
        if ((filteredColumnData as any)[0]['autoSearchKey'] !== null) {
            setshowDrop(true);
            setDropDownField((filteredColumnData as any)[0].autoSearchKey as string)
        }
        else {
            setshowDrop(false);
        }

    }, [columnData]);

    useEffect(() => {
        const fetcOption = async () => {
            try {
                const data = await userServicer.getDropDownlist(dropDownField, null);
                const h = data.data;
                const transformedData = h.map((item: { [x: string]: any; }) => ({
                    OptionID: item[Object.keys(h[0])[0]],
                    OptionName: item[Object.keys(h[0])[1]] === undefined ? item[Object.keys(h[0])[0]] : item[Object.keys(h[0])[1]]
                }));
                setOptions(transformedData);
            }
            catch {
                console.log("Error to fetch Dropdownlist");

            }

        }
        if (dropDownField !== null && dropDownField !== undefined) {
            fetcOption();
        }
    }, [dropDownField])


    let val3 = { OptionName: '' };
    if (options !== undefined && options !== null && filterData !== undefined && filterData !== null) {
        let val2 = options.filter((item: any) => item.OptionID === filterData[0]?.value);
        let val4 = val2.length > 0 ? Object.values(val2[0]) : '';
        val3.OptionName = val4[1] !== undefined ? String(val4[1]) : '';
    } else {
        console.log('options or inputs[field.tableFieldName] is undefined or null');
    }


    return (
        <div style={{ width: '100%' }}>
            <div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="popup_filter1" >
                        <div className="popup_filter2">
                            <FormControl variant="standard"

                            //sx={{ m: 1, width: 200, minWidth: 120, }}
                            >
                                <InputLabel id="demo-simple-select-standard-label" sx={{
                                    fontSize: '12px', color: 'var(--body-main-dark-color)', borderColor: 'var(--body-main-dark-color)',
                                }}> Search Field </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedField}
                                    // value={filterData.length > 0? typeof selectedField : selectedField.tableFieldName }
                                    onChange={handleChangeMUI}
                                    label="Search Field"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 140,
                                                overflowY: 'auto',
                                            },
                                        },
                                    }}
                                >
                                    {tableColumns.map((option, i) => (
                                        <MenuItem key={i} value={option.tableFieldName}>{option.headerName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div style={{ marginTop: '3px' }}>
                                {
                                    showDrop ?
                                        <>

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={options}
                                                value={val3}
                                                onChange={(event, newValue: any) => {
                                                    handleDatafromPOP([{ key: selectedField, value: newValue != null ? newValue.OptionID : '' }])
                                                    handleDatafromPOPforchips([{ key: selectedFieldforChips, value: newValue != null ? newValue.OptionName : '' }])
                                                }
                                                }
                                                getOptionLabel={(option: any) => (option.OptionName)}
                                                isOptionEqualToValue={(option, value) => option.OptionName === value.OptionName}
                                                // getOptionKey={(option) =>  (option.OptionName)}
                                                
                                                renderOption={(props, option, index) => {
                                                   
                                                    return (
                                                      <Box component="li" {...props} key={`${option.OptionID}-${index.index}`}>
                                                        {option.OptionName}
                                                      </Box>
                                                    );
                                                  }}
                                                sx={{ width: 180, overflowY: 'auto', overflowX: 'hidden', marginRight: 1 }}
                                                renderInput={(params) => (
                                                    <TextField

                                                        {...params}
                                                        label={selectedFieldforChips}
                                                        //label="State Name"
                                                        variant="standard"
                                                        name="stateId"
                                                        InputLabelProps={{
                                                            style: {
                                                                color: 'var(--body-main-dark-color)',
                                                                fontSize: '12px',
                                                                borderColor: 'red',

                                                            },
                                                        }}

                                                    />
                                                )}

                                            />

                                        </>


                                        :
                                        <FormControl variant="standard"
                                        //sx={{ m: 1, width: 200, minWidth: 120 }}
                                        >
                                            <TextField
                                                variant="standard"
                                                value={filterDataforChips.find(item => item.key === selectedFieldforChips)?.value || ''}
                                                label="search"
                                                onChange={(e) => {
                                                    handleDatafromPOP([{ key: selectedField, value: e.target.value[0] === '%' ? '`' : (e.target.value).trim() }]);
                                                    handleDatafromPOPforchips([{ key: selectedFieldforChips, value: e.target.value }])
                                                }}

                                                InputLabelProps={{
                                                    style: {
                                                        color: 'var(--body-main-dark-color)',
                                                        fontSize: '12px',
                                                        borderColor: 'red',
                                                    },
                                                }}
                                                InputProps={{
                                                    style: {
                                                        width: '200px',
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                }

                            </div>
                            <div className="center-pop">

                                {

                                    buttonSum.map((item: any, btnIndex) => (
                                        <React.Fragment key={`${btnIndex}-${'btn'}`}>
                                            {item === AccessLevel.Filter && (<button key={`${btnIndex}`} className="btn12" onClick={() => setOpen(true)}><FaFilter /></button>)}
                                            {item === AccessLevel.Export && (<ExportToCSV csvData={tableData} headerName={csvHeaderColumns} fileName={(ReportConfigData as any).exportReportName} />)}
                                            {item === AccessLevel.AddNew && (<button key={`${btnIndex}-${'btn'}`} className="btn12" onClick={() => handleAddButton()}><Add /></button>)}
                                        </React.Fragment>
                                    ))
                                    
                                }
                                {/* <button className="btn12" style={{ fontSize: 18, paddingTop: 6 }} onClick={(e) => requestForReport()} ><Download /></button> */}

                                <Popup contentStyle={{ height: 250, width: 450, padding: 0 }} open={open} onClose={() => setOpen(false)}>
                                    <Popfilterform pageID={pageID} columndata={tableColumns} ReportConfigData={ReportConfigData} filterformData={filterData} filterFormDataforChips={filterDataforChips} sendDataToParent={handleDatafromPOP} sendDataToParentforChips={handleDatafromPOPforchips} openClose={() => setOpen(false)} />
                                </Popup>
                            </div>
                        </div>
                        <div className="chips-container">
                            <Stack direction="column" spacing={1}>
                                {filterDataforChips.map((item, index) => (

                                    <Chip key={index} sx={{ backgroundColor: '#d3eafc', border: '1px solid var(--body-main-dark-color)', color: 'var(--body-main-dark-color)', fontWeight: '500' }} label={`${item.key} : ${item.value}`} variant="outlined" onDelete={() => handleDelete(index)} />

                                ))}
                            </Stack>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EnhancedSearchbar;

