import { FaUser } from "react-icons/fa";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { useState } from "react";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CloseIcon from '@mui/icons-material/Close';
import { RequestReport } from "../../Commen_Services/RequestReport.Service";
import { userServicer } from "../../Commen_Services/user.services";
import customToast from "../../Commen_Component/Toast/CustomToast";


interface FilterDetails {
    date_from: '',
    date_to: ''
}

interface ColumnData {
    autoSearchKey: string;
    tableFieldName: string;
    headerName: string;
    showFieldDDL: string;

}

const CollectionTranscationReport = () => {

    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [inputs, setInputs] = useState<FilterDetails>({
        date_from: '',
        date_to: ''
    });

    const [error, setError] = useState<FilterDetails>({
        date_from: '',
        date_to: ''
    });

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    const validateDate = (dateString: any) => {

        // if (!dateString) {
        //     return false;
        // }
        const inputDate = new Date(dateString);
        const today = new Date();
        if (inputDate > today) {
            return false;
        }
        return true;
    };

    const handleChangeforStartDate = ((event: any) => {


        const name = event.target.name;
        const value = event.target.value;
        setStartDate(value);

        if (value == "") {
            setInputs((values: any) => ({ ...values, [name]: value }));
            // if(endDate == "")
            // {
            //         setEndDate(value);
            //         setError((prevErrors: any) => ({ ...prevErrors, previous_Job_Start_date: '' }));
            //         setError((prevErrors: any) => ({ ...prevErrors, previous_Job_End_date: '' }));
            // }
            if (endDate !== "") {
                setError((prevErrors: any) => ({ ...prevErrors, date_from: 'Please enter a valid date' }));
            }
            else {
                setEndDate(value);
                setError((prevErrors: any) => ({ ...prevErrors, date_from: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, date_to: '' }));
            }
        }
        else if (!validateDate(value)) {
            setError((prevErrors: any) => ({ ...prevErrors, date_from: 'Please enter a valid date' }));
        }
        else {

            const startDateCheck = new Date(value);
            const endDateCheck = new Date(endDate);
            const ch = startDateCheck > endDateCheck;
            if (startDateCheck > endDateCheck) {
                setError((prevErrors: any) => ({ ...prevErrors, date_from: 'Please enter a valid date' }));
                setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));
                setStartDate(value);
            }
            else {
                setError((prevErrors: any) => ({ ...prevErrors, date_from: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, date_to: '' }));
                setInputs((values: any) => ({ ...values, [name]: value }));
                setStartDate(value);
            }

            if (!validateDate(endDate)) {
                setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));

            }

        }
    });

    const handleChangeforEndDate = ((event: any) => {

        const name = event.target.name;
        const value = event.target.value;
        setEndDate(value);
        if (value == "") {

            setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));
            if (startDate == "") {
                setStartDate(value);
                setError((prevErrors: any) => ({ ...prevErrors, date_from: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, date_to: '' }));
            }
        }
        else if (!validateDate(value)) {
            setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));
        }
        else {

            const startDateCheck = new Date(startDate);
            const endDateCheck = new Date(value);
            const ch = startDateCheck > endDateCheck;
            if (startDate == "") {
                setEndDate(value);
                setError((prevErrors: any) => ({ ...prevErrors, date_from: 'Please enter a valid date' }));
            }
            else if (startDateCheck > endDateCheck) {
                setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));
            }
            else {
                setError((prevErrors: any) => ({ ...prevErrors, date_to: '' }));
                setError((prevErrors: any) => ({ ...prevErrors, date_from: '' }));
                setInputs((values: any) => ({ ...values, [name]: value }));
                setEndDate(value);
            }


        }

    });

    const ValidationCheck = () => {
        if (inputs.date_from !== '' && inputs.date_to !== '') {
            return true;
        }
        else {
            if (inputs.date_from == '') {
                setError((prevErrors: any) => ({ ...prevErrors, date_from: 'Please enter a valid date' }));
            }
            if (inputs.date_to == '') {
                setError((prevErrors: any) => ({ ...prevErrors, date_to: 'Please enter a valid date' }));
            }
            return false;
        }
    }



    const requestForReport = async () => {
        
        if(ValidationCheck())
        {
            const tempFilterData = Object.entries(inputs)
                .map(([key, value]) => `${key}='${value}'`)
                .join(',');

                const [tableConfig, reportConfigData] = await Promise.all([
                    userServicer.tableConfig(16),
                    userServicer.reportConfig(16)               
                ]);
            const tableColums: ColumnData[] = tableConfig.data;
            const filteredColumnData = tableColums.filter((item: any) => item.showFieldDDL === true);
            const tempExportColumns = filteredColumnData.map((item: any) => item.tableFieldName).join(',');

            const data = {
                "reportPageId": 16,
                "report_FilterColumn": tempFilterData,
                "report_Exportcolumn": tempExportColumns,
                "reportName" : reportConfigData?.data.exportReportName,
            }
            await RequestReport.SaveorUpdate(data).then(user => {

                if (user.statusCode === 200) {
                    if (user.status === false) {
                        customToast.Warning(user.message);
                    }
                    else {
                        customToast.success(user.message);
                    }
                    clearForm();
                }
            });
        }
    }

    const clearForm = () => {
        setInputs({date_from: '',date_to:''});
        setError({date_from: '',date_to:''});
        setStartDate('');
        setEndDate('');
    }
    return (
        <>
            <div>
                <div className="shadow">
                    <div className="bg-line">
                        <span className="bg-head"><FaUser /> &nbsp;Collection Transcation Details Report </span>
                        {/* <span className="bg-head margin1" >Headquater &nbsp;<GrNext />&nbsp;Headquater List</span> */}
                    </div>
                    <hr className="colorgraph" />
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={2.5} key={1}>
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="date_from"
                                type="date"
                                value={startDate}
                                label="From Date"
                                onChange={handleChangeforStartDate}
                                focused={!!inputs.date_from}
                                error={!!error.date_from}
                                helperText={error.date_from}
                                InputProps={{
                                    inputProps: {
                                        className: startDate ? '' : 'custom-date-input',
                                    },
                                    // readOnly: isViewData,
                                    style: {
                                        color: startDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.date_from ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.date_from ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.date_from ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />

                        </Grid>

                        <Grid item xs={2} sm={4} md={2.5} key={2}>
                            <TextField
                                sx={{ marginRight: 1 }}
                                id="standard-basic"
                                variant="standard"
                                name="date_to"
                                type="date"
                                value={endDate}
                                label="End Date"
                                onChange={handleChangeforEndDate}
                                focused={!!inputs.date_to}
                                error={!!error.date_to}
                                helperText={error.date_to}
                                InputProps={{
                                    inputProps: {
                                        className: endDate ? '' : 'custom-date-input',
                                    },
                                    //readOnly: isViewData,
                                    style: {
                                        color: endDate ? '#243C5C' : 'transparent',
                                        fontSize: '14px',
                                        width: '200px',
                                        borderColor: error.date_to ? 'red' : undefined
                                    },
                                    onInput: (event: any) => {

                                        const value = event.target.value;
                                        if (value == "") {
                                            setInputs((values: any) => ({ ...values, [event.target.name]: event.target.value })
                                            )

                                        }
                                    }
                                }}

                                InputLabelProps={{
                                    style: {
                                        color: '#243C5C',
                                        fontSize: '12px',
                                        borderColor: error.date_to ? 'red' : undefined
                                    },
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontSize: error.date_to ? '9px' : '12px',
                                        maxWidth: '210px'

                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={1} sm={4} md={1.3} key={3}>
                            <button
                                className={`btn123`}
                                onClick={(event) => { requestForReport(); }}
                                style={{ paddingRight: 12, height: '30px', marginRight: 12 }}
                            >
                                <ImportExportIcon /> &nbsp;
                                Export
                            </button>
                        </Grid>
                        <Grid item xs={1} sm={4} md={2} key={4}>
                            <button
                                className={`btn123`}
                                onClick={(event) => { clearForm(); }}
                                style={{ paddingRight: 12, height: '30px', marginRight: 12 }}
                            >
                                <CloseIcon /> &nbsp;
                                Clear
                            </button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}

export default CollectionTranscationReport;